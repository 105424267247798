<template>
<v-card>
  <v-toolbar flat style="border-bottom: 1px solid #c0c0c0">
       <v-text-field
              v-model="searchText"
              label="Search"
              single-line

              append-icon="mdi-magnify"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
      <v-spacer />
       <v-btn  color="primary" @click="addUserDialog = true" v-if="isAdminUser">
        Create User
      </v-btn>
    </v-toolbar>
      <v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
                  :items-per-page="30"
          :footer-props="{
          showFirstLastPage: true,
          'items-per-page-options': [5, 10, 30, -1]
            }"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
    >
      <template v-slot:item.userId="props">
        {{ props.item.user.id }}
      </template>
      <template v-slot:item.fullName="props">
        {{ props.item.user.firstName + " " + props.item.user.lastName }}
      </template>
      <template v-slot:item.email="props">
        {{ props.item.user.email }}
      </template>
      <template v-slot:item.phoneNumber="props">
        <span :mask="phoneMask">{{ props.item.user.phoneNumber }}</span>
      </template>
      <template v-slot:item.roles="props">
          <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          :color="props.item.userAccess.isAdmin ? 'primary' : 'secondary'">
                      mdi-account-star
                  </v-icon>
              </template>
              <span>Admin</span>
          </v-tooltip>

          <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          :color="
                props.item.userAccess.isTechnician ? 'primary' : 'secondary'
              ">
                      mdi-wrench
                  </v-icon>
              </template>
              <span>Technician</span>
          </v-tooltip>

          <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          :color="
                props.item.userAccess.isTechnicianManager
                  ? 'primary'
                  : 'secondary'
              ">
                      mdi-account-supervisor
                  </v-icon>
              </template>
              <span>Technician Manager</span>
          </v-tooltip>

          <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          :color="
                props.item.userAccess.isReadOnly
                  ? 'primary'
                  : 'secondary'
              ">
                      mdi-pencil-off
                  </v-icon>
              </template>
              <span>Read Only</span>
          </v-tooltip>

          <v-icon v-if="props.item.userAccess.isTechnicianManager">
              mdi-worker
          </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
          <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="loading"
                          v-if="isAdminUser"
                          @click="updateUser(item)">
                      mdi-pencil
                  </v-icon>
              </template>
              <span>Edit User</span>
          </v-tooltip>
          <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          v-if="isAdminUser"
                          @click="deleteUser(item)">
                      mdi-delete
                  </v-icon>
              </template>
              <span>Delete User</span>
          </v-tooltip>
          <!--<v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          v-if="isAdminUser"
                         
                          @click="deleteUser(item)">
                      props.item.isLockedOut ? mdi-lock : mdi-lock-open
                  </v-icon>
              </template>
              <span>Unlock User</span>
          </v-tooltip>-->
      </template>
    </v-data-table>
    <!-- Modals -->
    <userAdd
      :addUserDialog="addUserDialog"
      v-on:cancel="addUserDialog = false"
      v-on:saved="getUsers(); addUserDialog = false"
         v-on:userWasAdded="refreshUsers"
    />
    <userDelete
      v-if="selectedUser && deleteUserDialog"
      :deleteUserDialog="deleteUserDialog"
      v-on:cancel="deleteUserDialog = false"
      v-on:userWasDeleted="getUsers(); deleteUserDialog = false"
      :user="selectedUser"
    />
    <userUpdate
      v-if="selectedUser && updateUserDialog"
      :updateUserDialog="updateUserDialog"
      v-on:cancel="updateUserDialog = false"
      v-on:userWasUpdated="getUsers(); updateUserDialog = false"
      :user="selectedUser"
    />
    <!-- End Modals -->
      </v-card-text>
</v-card>
</template>

<script>
import moment from "moment";
import userAdd from "./userAdd.vue";
import userUpdate from "./userUpdate.vue";
import userDelete from "./userDelete.vue";
import services from "@/services"
import {ToSearchPageRequest} from "@/models"
 export default {
  name: "users",
  components: {
    userAdd,
    userUpdate,
    userDelete,
  },
  data: () => ({
    searchText: "",
    totalCount: 0,
    items: [],
    loading: false,
    options: {
      groupBy: [],
      groupDesc: [],
     // itemsPerPage: 15,
      multiSort: false,
      mustSort: false,
      //page: 1,
      sortBy: [],
      sortDesc: [],
    },
    addUserDialog: false,
    deleteUserDialog: false,
    updateUserDialog: false,
    selectedUser: {},
    timer: null,
    headers: [
      // {
      //   text: "User #",
      //   align: "start",
      //   sortable: "false",
      //   value: "userId",
      // },
      {
        text: "Full Name",
        align: "start",
        sortable: true,
        value: "fullName",
      },
      { text: "Email", value: "email", algin: "left" },
      { text: "Phone Number", value: "phoneNumber", align: "left" },
      {
        text: "Roles",
        value: "roles",
        sortable: false,
        align: "center",
      },
      { text: "Actions", value: "actions", align: "center" },
    ],
    phoneMask: [
      "(",
      /\d/,
      /\d/,
      /\d/,
      ") ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
  }),
  watch: {
    options: {
      async handler() {
        await this.getUsers();
      },
      deep: true,
    },
    searchText: {
      async handler() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(async () => {
          await this.getUsers();
        }, 1000);
      },
      deep: true,
    },
  },
  computed: {
        isAdminUser() {
      return this.$store.state.user.isAdmin;
    },
  },
  methods: {

    
    async getUsers() {
      this.loading = true;
      var request = ToSearchPageRequest(this.searchText, this.options);
       await services.adminService.getAllUsers(request).then(data => {
          this.items = data.results;
          this.totalCount = data.totalCount;
      }).finally(() => {
        this.loading = false;
      });
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD HH:MM:SS");
    },
    sendNotificationForSession(session) {
      this.$store.dispatch("dashboard/send_notification", session.sessionId);
      return;
    },
    deleteUser(rowData) {
      this.selectedUser = rowData;
      this.deleteUserDialog = true;
      },
    UnlockUser(rowData) {
      this.selectedUser = rowData;
      
    },
    updateUser(rowData) {
      this.selectedUser = rowData;
      this.updateUserDialog = true;
    },
    refreshUsers(createdUser) {
      this.addUserDialog = false;
      this.searchText = createdUser.lastName;
      this.getUsers();
    }
  },
  async mounted() {
    await this.getUsers();
  },
};
</script>
