var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-toolbar',{staticStyle:{"border-bottom":"1px solid #c0c0c0"},attrs:{"flat":""}},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","append-icon":"mdi-magnify","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-spacer'),(_vm.isAdminUser)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.addUserDialog = true}}},[_vm._v(" Create User ")]):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":30,"footer-props":{
          showFirstLastPage: true,
          'items-per-page-options': [5, 10, 30, -1]
            },"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.userId",fn:function(props){return [_vm._v(" "+_vm._s(props.item.user.id)+" ")]}},{key:"item.fullName",fn:function(props){return [_vm._v(" "+_vm._s(props.item.user.firstName + " " + props.item.user.lastName)+" ")]}},{key:"item.email",fn:function(props){return [_vm._v(" "+_vm._s(props.item.user.email)+" ")]}},{key:"item.phoneNumber",fn:function(props){return [_c('span',{attrs:{"mask":_vm.phoneMask}},[_vm._v(_vm._s(props.item.user.phoneNumber))])]}},{key:"item.roles",fn:function(props){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":props.item.userAccess.isAdmin ? 'primary' : 'secondary'}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-star ")])]}}],null,true)},[_c('span',[_vm._v("Admin")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":props.item.userAccess.isTechnician ? 'primary' : 'secondary'}},'v-icon',attrs,false),on),[_vm._v(" mdi-wrench ")])]}}],null,true)},[_c('span',[_vm._v("Technician")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":props.item.userAccess.isTechnicianManager
                  ? 'primary'
                  : 'secondary'}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-supervisor ")])]}}],null,true)},[_c('span',[_vm._v("Technician Manager")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":props.item.userAccess.isReadOnly
                  ? 'primary'
                  : 'secondary'}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-off ")])]}}],null,true)},[_c('span',[_vm._v("Read Only")])]),(props.item.userAccess.isTechnicianManager)?_c('v-icon',[_vm._v(" mdi-worker ")]):_vm._e()]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.isAdminUser)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.updateUser(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Edit User")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.isAdminUser)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.deleteUser(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Delete User")])])]}}])}),_c('userAdd',{attrs:{"addUserDialog":_vm.addUserDialog},on:{"cancel":function($event){_vm.addUserDialog = false},"saved":function($event){_vm.getUsers(); _vm.addUserDialog = false},"userWasAdded":_vm.refreshUsers}}),(_vm.selectedUser && _vm.deleteUserDialog)?_c('userDelete',{attrs:{"deleteUserDialog":_vm.deleteUserDialog,"user":_vm.selectedUser},on:{"cancel":function($event){_vm.deleteUserDialog = false},"userWasDeleted":function($event){_vm.getUsers(); _vm.deleteUserDialog = false}}}):_vm._e(),(_vm.selectedUser && _vm.updateUserDialog)?_c('userUpdate',{attrs:{"updateUserDialog":_vm.updateUserDialog,"user":_vm.selectedUser},on:{"cancel":function($event){_vm.updateUserDialog = false},"userWasUpdated":function($event){_vm.getUsers(); _vm.updateUserDialog = false}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }