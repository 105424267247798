<template>
  <BaseModal
    :loading="loading"
    :dialog="deleteUserDialog"
    v-on:cancel="$emit('cancel')"
  >
    <template v-slot:title>
      Delete User
    </template>
    <template v-slot:body v-if="user && user.user">
      Are you sure you want to delete {{ user.user.firstName }}
      {{ user.user.lastName }} ?
    </template>
    <template v-slot:actions>
      <v-btn
        @click="deleteUser"
        large
        color=""
        depressed
        class="v-btn--text primary--text"
        :loading="loading"
        submit
        >submit</v-btn
      >
    </template>
  </BaseModal>
</template>

<script>
import services from "@/services";

export default {
  name: "user-delete",
  props: {
    user: {
      type: Object,
      default: null,
    },
    deleteUserDialog: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async deleteUser() {
      this.loading = true;
      let that = this;
      await services.adminService
        .deleteUser(this.$props.user.user)
        .then((response) => {
          if (response) {
            that.$store.commit(
              "snack/set_snack",
              "User successfully deleted!"
            );
            that.$emit("userWasDeleted");
          } else {
            that.$store.commit(
              "snack/set_snack",
              "User delete failed! Please try again."
            );
          }
        })
        .catch(() => {
          that.$store.commit(
            "snack/set_snack",
            "User delete failed! Please try again."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {},
};
</script>
