<template>
  <v-container fluid class="down-top-padding ">
    <!-- <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb> -->
   <users/>
  </v-container>
</template>

<script>
import users from "./users.vue";

export default {
  name: 'users-index',
  components: {
    users,
  },
  data: () => ({
    page: {
      title: "Users",
      icon: "mdi-lock",
    },
    breadcrumbs: [
      {
        text: "administration",
        disabled: true,
      },
    ],
    loading: false,
  }),
};
</script>
