<template>
  <BaseModal
    :loading="loading"
    :dialog="addUserDialog"
    v-on:cancel="$emit('cancel')"
  >
    <template v-slot:title>
      Create New User
    </template>
    <template v-slot:body>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-validate="'required|max:450'"
          v-model="request.firstName"
          :counter="450"
          :error-messages="errors.collect('firstName')"
          label="First Name"
          data-vv-name="firstName"
          required
          color="secondary"
          outlined
          prepend-icon="mdi-account"
        ></v-text-field>

        <v-text-field
          v-validate="'required|max:450'"
          v-model="request.lastName"
          :counter="450"
          :error-messages="errors.collect('lastName')"
          label="Last Name"
          data-vv-name="lastName"
          required
          color="secondary"
          outlined
          prepend-icon="mdi-account"
        ></v-text-field>

        <v-text-field
          v-validate="'required|max:450'"
          v-model="request.title"
          :counter="450"
          :error-messages="errors.collect('title')"
          label="Title"
          data-vv-name="title"
          required
          color="secondary"
          outlined
          prepend-icon="mdi-format-title"
        ></v-text-field>

        <v-text-field
          v-validate="'required|max:450'"
          v-model="request.email"
          :counter="450"
          :error-messages="errors.collect('email')"
          label="Email"
          data-vv-name="email"
          required
          color="secondary"
          outlined
          prepend-icon="mdi-at"
        ></v-text-field>

        <v-text-field
          v-validate="'required|max:200'"
          v-model="request.password"
          :counter="200"
          :error-messages="errors.collect('password')"
          label="Password"
          data-vv-name="password"
          required
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          @click:append="() => (show1 = !show1)"
          color="secondary"
          outlined
          prepend-icon="mdi-lock"
        ></v-text-field>
        <v-text-field
          v-validate="'required|max:14'"
          v-model="request.phoneNumber"
          :counter="14"
          v-mask="phoneMask"
          :error-messages="errors.collect('phoneNumber')"
          label="Phone Number"
          data-vv-name="phoneNumber"
          required
          color="secondary"
          outlined
          prepend-icon="mdi-phone"
        ></v-text-field>
        <v-select
          v-validate="'required'"
          data-vv-name="select"
          :items="availableRoles"
          clearable
          v-model="request.userRole"
          item-value="name"
          item-text="name"
          label="Select a user role"
          color="secondary"
          outlined
          prepend-icon="mdi-account-lock-outline"
        />
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn
        @click="submit"
        large
        color=""
        depressed
        class="v-btn--text primary--text"
        :disabled="!valid || loading"
        submit
        >submit</v-btn
      >
      <v-btn
        @click="clear"
        color=""
        depressed
        class="v-btn--text error--text"
        :disabled="loading"
        >clear</v-btn
      >
    </template>
  </BaseModal>
</template>

<script>
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    addUserDialog: {
      type: Boolean,
      default: false,
    },
  },
  name: "userAdd",
  data: () => ({
    loading: false,
    show1: false,
    valid: false,
    phoneMask: [
      "(",
      /\d/,
      /\d/,
      /\d/,
      ") ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    request: {
      firstName: "",
      lastName: "",
      title: "",
      email: "",
      password: "",
      phoneNumber: "",
      userRole: "",
    },
    dictionary: {
      attributes: {
        email: "E-mail Address",
        // custom attributes
      },
      custom: {
        name: {
          required: () => "Name can not be empty",
          max: "The name field may not be greater than 10 characters",
          // custom messages
        },
        select: {
          required: () => "Select field is required",
        },
      },
    },
  }),
  computed: {
    availableRoles() {
      return this.$store.state.admin.availableRoles;
    },
  },
  methods: {
    submit() {
      this.$validator.validateAll();
      this.loading = true;
      this.$store
        .dispatch("admin/register_user", this.request)
        .then((response) => {
          if (response && response.succeeded) {
            this.$store.commit(
              "snack/set_snack",
              "User was successfully added."
            );

            this.$emit("userWasAdded", this.request);
            return this.clear();
          } else {
            if (response.errors && response.errors.length > 0) {
              var that = this;
              response.errors.forEach((element) => {
                that.$store.commit("snack/set_snack", element.description);
              });
            } else {
               return this.$store.commit(
            "snack/set_snack",
            "Failed to save registration. Please try again."
          );
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          return this.$store.commit(
            "snack/set_snack",
            "Failed to save registration. Please try again."
          );
        })
        .finally(() => (this.loading = false));
    },
    clear() {
      this.request.firstName = "";
      this.request.lastName = "";
      this.request.title = "";
      this.request.email = "";
      this.request.phoneNumber = "";
      this.request.password = "";
      this.request.userRole = "";
      this.$validator.reset();
    },
  },
  mounted: function() {
    this.$validator.localize("en", this.dictionary);

    this.loading = true;
    this.$store
      .dispatch("admin/get_available_roles")
      // eslint-disable-next-line no-unused-vars
      .finally(() => (this.loading = false));
  },
};
</script>
