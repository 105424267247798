function ToSearchPageRequest(searchText, options) {
    var searchRequest = {
        SearchText: searchText,
        Page: {
            perPage: options.itemsPerPage,
            currentPage: options.page,
            sortBy: options.sortBy,
            sortDesc: options.sortDesc,
          }
    };

    return searchRequest;
}

export { ToSearchPageRequest }